<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button
        class="filter-item"
        type="primary"
        @click="checkDialogFormVisible(true, {}, 'CREATE')"
        >新增</el-button
      >
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      style="width: 100%;margin-bottom: 20px;"
      row-key="id"
      border
      default-expand-all
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column prop="name" label="标签名称" sortable width="280">
      </el-table-column>
      <el-table-column prop="icon" label="图标">
        <template slot-scope="scope" v-if="scope.row.icon">
          <el-image :src="scope.row.icon" style="width: 50px" fit="cover" />
        </template>
        <template v-else>暂未上传图标</template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="checkDialogFormVisible(true, scope.row, 'CREATE')"
            >创建子标签</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="checkDialogFormVisible(true, scope.row, 'UPDATE')"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!--    编辑和新增-->
    <el-dialog
      :title="DIALOG_TITLE_TYPE[dialogType]"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-position="left"
        label-width="150px"
        style="width: 80%; margin-left:50px;"
      >
        <el-form-item label="标签名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入" />
        </el-form-item>

        <el-form-item label="图标（base64编码）" prop="icon">
          <el-input
            placeholder="请输入"
            type="textarea"
            v-model="form.icon"
            :autosize="{ minRows: 2, maxRows: 8 }"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="checkDialogFormVisible(false, {}, '')"
          >取消</el-button
        >
        <el-button
          type="primary"
          @click="saveAddOrEditData"
          :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { DIALOG_TITLE_TYPE } from "@/utils/enumUtile";
import { getArtCategoryList, artCategoryCreate } from "@/api/art";

export default {
  computed: {
    DIALOG_TITLE_TYPE() {
      return DIALOG_TITLE_TYPE;
    }
  },
  components: {},
  filters: {},
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10
      },
      dialogFormVisible: false, // 新增 or 编辑的弹框
      dialogType: "",
      rules: {
        type: [{ required: true, message: "类型不能为空", trigger: "change" }],
        name: [
          { required: true, message: "标签名称不能为空", trigger: "change" }
        ]
      },
      form: {
        id: 0,
        parentId: 0,
        name: "",
        icon: ""
      },
      btnLoading: false
    };
  },
  created() {
    this.getList();
  },
  watch: {},
  methods: {
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    /**
     * 查询用户列表
     */
    getList() {
      this.listLoading = true;
      getArtCategoryList(this.listQuery)
        .then(response => {
          this.list = response.data || [];
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    /**
     * 切换状态
     * @param status
     * @param editObj
     * @param type
     */
    checkDialogFormVisible(status, editObj = {}, type) {
      if (editObj && Object.keys(editObj).length !== 0) {
        this.form.parentId = editObj.id;
      }
      if (status && type === "UPDATE") {
        this.form.name = editObj.name;
        this.form.icon = editObj.icon;
        this.form.parentId = editObj.parent_id;
        this.form.id = editObj.id;
      }
      if (!status) {
        this.form.name = "";
        this.form.icon = "";
        this.form.parentId = 0;
        this.form.id = 0;
        this.$refs.form.resetFields();
      }
      this.dialogType = type;
      this.dialogFormVisible = status;
    },
    /**
     * 确认添加还是编辑
     */
    saveAddOrEditData() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          const params = this.form;
          this.btnLoading = true;
          artCategoryCreate(params)
            .then(() => {
              this.getList();
              this.checkDialogFormVisible(false);
              this.$notify({
                title: "成功",
                message: "保存成功",
                type: "success",
                duration: 2000
              });
            })
            .finally(() => {
              this.btnLoading = false;
            });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
